@use "../../master.scss";

.confirmation-modal {
    .description {
        font-size: 16px;
        @include master.line-wrap(3);

        @include master.media-mobile {
            font-size: 14px;
        }
    }

    .bottom-btn {
        margin-top: 30px;
        @include master.flex-end();
        gap: 15px;

        button {
            padding: 10px 30px;
            font-size: 15px;
            font-family: master.$font-arial-bold;

            @include master.media-mobile {
                padding: 8px 20px;
                font-size: 13px;
            }
        }
    }
}