@use "../../../../../master.scss";

.report-modal {
  box-shadow: 0px 3px 6px #00000029;

  .ant-modal-close {
    top: 16px;
    width: 32px;
    height: 32px;
    background: master.$light-grey;
    color: master.$black;
    border-radius: 50px;
    @include master.flex-center();

    &:hover {
      background: master.$grey;
    }

    @include master.media-mobile {
      width: 20px;
      height: 20px;

      .ant-modal-close-x {
        font-size: 13px;
      }
    }
  }

  .content-box {
    padding: 30px 10px;

    .title-wrapper {
      padding: 0 20px 20px;

      h2 {
        font-size: 34px;
        padding-left: 12px;
        font-family: master.$font-arial-bold;

        @include master.media-smalldevice {
          font-size: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 12px);
          width: 7px;
          height: 24px;
          background: master.$theme-color-primary;

          @include master.media-mobile {
            height: 6px;
          }
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
    }
    .feedback-form {
      padding: 0 20px;
      textarea {
        height: 160px;
        margin-top: 5px;
        &:disabled {
          background: transparent;
          border: 1px solid master.$border-color;
          color: master.$black;
          &::placeholder {
            color: master.$black;
          }
        }
      }
    }

    .bottom-wrapper {
      margin-top: 45px;
      @include master.flex-end();

      button {
        font-size: 16px;
        min-width: 160px;
        border-radius: 30px;
        padding: 12px 16px;
        font-family: master.$font-arial-bold;

        &.back-btn {
          position: absolute;
          left: 0;
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }

      @include master.media-smalldevice {
        margin-top: 30px;
      }
    }

    @include master.media-smalldevice {
      padding: 30px 0;
    }
  }
}
