@use "../../../../../master.scss";

.input-wrapper {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  .inputbox-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    .input-box {
      border: 1px solid master.$border-color;
      background: master.$white;
      border-radius: 8px;
      padding: 8px 24px;
      width: 100%;

      .input-form {
        display: flex;
        align-items: flex-end;
        gap: 12px;

        .upload-button {
          border: none;
          min-width: 40px;
          height: 40px;
          // font-size: 16px;
          font-size: 0px;
          background: master.$light-grey;
          @include master.flex-center();
          border-radius: 50px;
          cursor: pointer;

          input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: none;
          }
          .upload-icon {
            font-size: 16px;
            @include master.media-smalldevice {
              font-size: 14px;
            }
            &.loading {
              animation: rotate 2s linear infinite;
            }
          }

          &:hover {
            background: master.$grey;
          }
          &:disabled:hover {
            background: master.$light-grey;
            cursor: auto;
          }

          @include master.media-smalldevice {
            min-width: 35px;
            height: 35px;
          }
        }

        .ant-input {
          padding: 0;
          border: none;
          box-shadow: none;
          font-size: 16px;
          resize: none;
          min-height: 20px;
          max-height: 140px;
          line-height: 20px;
          height: 20px;
          width: 100%;
          padding-left: 10px;

          &::placeholder {
            color: master.$dark-grey;
          }

          @include master.media-tablet {
            border: none;
            padding: 0;
            font-size: 13px;
          }
          @include master.media-tablet {
            max-height: 100px;
          }
        }

        .chat-selector {
          .selected-chat {
            min-width: auto;
            border: none;
            background: master.$theme-color;
            color: master.$white;
            width: 220px;
            text-align: left;
            font-size: 15px;
            padding: 15px 16px;
            border-radius: 50px;
            text-transform: capitalize;
            cursor: pointer;
            @include master.flex-start();

            span {
              @include master.text-wrap();
              width: calc(100% - 20px);
            }

            .select-toggle {
              position: absolute;
              right: 18px;
              transition: 0.3s all;

              @include master.media-tablet {
                right: 8px;
                font-size: 10px;
              }
            }

            @include master.media-tablet {
              width: 80px;
              padding: 8px 10px;
              font-size: 13px;
            }
          }

          .chat-options {
            position: absolute;
            bottom: 48px;
            width: 100%;
            background: master.$light-black;
            border-radius: 10px;
            overflow: hidden;
            height: 0px;
            transition: height 0.7s all;

            li {
              display: block;

              button {
                border: none;
                background: transparent;
                width: 100%;
                font-size: 13px;
                line-height: 18px;
                padding: 10px 16px;
                color: master.$white;
                text-transform: capitalize;
                cursor: pointer;
                transition: 0.3s all;
                text-align: left;

                &:hover {
                  background: master.$darkest-grey;
                }

                @include master.media-mobile {
                  padding: 5px 14px;
                  font-size: 12px;
                }
              }
            }

            @include master.media-tablet {
              width: calc(100% + 50px);
            }

            @include master.media-mobile {
              bottom: 45px;
            }
          }

          &.active {
            .selected-chat {
              .select-toggle {
                transform: rotate(180deg);
              }
            }

            .chat-options {
              height: max-content;
              padding: 4px 0;
            }
          }

          @include master.media-mobile {
            margin: 0;
          }
        }

        .send-btn {
          border-radius: 100px;
          min-width: 40px;
          height: 40px;
          font-size: 16px;
          padding: 0;
          @include master.flex-center();

          svg {
            margin: 0;
          }
          &:disabled {
            opacity: 0.6;
          }
          @include master.media-smalldevice {
            min-width: 35px;
            height: 35px;
            font-size: 14px;
          }
        }
        @include master.media-tablet {
          gap: 8px;
        }
      }
      .images-box {
        width: 100%;
        overflow-y: auto;

        .selected-images {
          width: max-content;
          display: flex;
          gap: 10px;

          .media-box {
            border-radius: 8px;
            margin-top: 15px;
            width: 60px;
            height: 60px;

            .media-progress {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.3);
              border-radius: 8px;
              z-index: 2;
              transition: 0.3s all;
              @include master.flex-center();
            }

            .error {
              position: absolute;
              background: rgba(0, 0, 0, 0.1);
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 8px;
              z-index: 5;
              @include master.flex-center();
              .icon {
                width: 35px;
                height: 35px;
                background: master.$error-color;
                border-radius: 8px;
                color: master.$white;
                @include master.flex-center();
              }
            }

            &.image img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 8px;
            }
            &.file {
              width: max-content;
              @include master.flex-center();
              background: master.$light-grey;
              .thumb {
                background: master.$theme-color-primary;
                width: 35px;
                height: 100%;
                border-radius: 8px 0 0 8px;
                @include master.flex-center();
                &.error-thumb {
                  background: master.$error-color;
                  color: master.$white;
                }
              }
              .detail {
                padding: 0 15px;
                min-width: 180px;
                h4 {
                  font-family: master.$font-arial-bold;
                  margin-bottom: 3px;
                  font-size: 14px;
                }
                p {
                  font-size: 12px;
                }
              }
            }

            .delete-btn {
              z-index: 99;
              position: absolute;
              border: none;
              right: -8px;
              top: -8px;
              width: 20px;
              height: 20px;
              font-size: 12px;
              background: master.$grey;
              @include master.flex-center();
              border-radius: 50px;
              cursor: pointer;
              transition: 0.3s all;
              opacity: 0;
            }

            &::before {
              pointer-events: none;
              border-radius: 8px;
              content: "";
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              top: 0;
              background: rgba(0, 0, 0, 0.3);
              z-index: 2;
              transition: 0.3s all;
              opacity: 0;
            }

            &:hover {
              &::before,
              .delete-btn {
                opacity: 1;
              }
            }
            @include master.media-mobile {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
      .desclaimer-message {
        margin-top: 8px;
        min-height: 15px;
        font-size: 13px;
        font-style: italic;
        @include master.media-mobile {
          font-size: 11px;
        }
      }

      @include master.media-tablet {
        padding: 8px 10px;
        border-radius: 8px;
      }
      &.reduced {
        width: calc(100% - 76px);

        @include master.media-smalldevice {
          width: calc(100% - 56px);
        }
      }
    }
    .voice-toggle {
      width: 56px;
      font-size: 20px;
      height: 56px;
      border-radius: 50px;
      background: transparent;
      border: none;
      @include master.flex-center();
      cursor: pointer;
      img {
        width: 90%;
      }
      @include master.media-smalldevice {
        width: 51px;
        height: 51px;
        font-size: 18px;
      }
    }
    @include master.media-smalldevice {
      gap: 10px;
    }
  }

  .tagline {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
    @include master.line-wrap(2);

    span {
      color: master.$venetian-red;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    @include master.media-tablet {
      font-size: 13px;
      margin-top: 8px;
    }

    @include master.media-mobile {
      line-height: 18px;
    }
    &.warning {
      margin-top: 15px;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
