@use "../../../../../master.scss";

.chat-box {
  height: 100%;

  .messages-wrapper {
    padding: 0 40px;
    height: calc(100% - 130px);
    min-height: calc(100% - 230px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .messages-list {
      width: 1200px;
      max-width: 100%;
      margin: 0 auto;
      padding: 20px 0;

      @include master.media-tablet {
        padding: 10px 0;
      }
    }

    .scroller-box {
      height: 1px;
      width: 100%;
    }

    .splash-box {
      @include master.flex-center-column();
      height: 100%;

      img {
        width: 480px;
      }

      h3 {
        color: master.$black;
        font-family: master.$font-arial-bold;
        // letter-spacing: 0.5px;
        font-size: 20px;
        width: 480px;
        max-width: 100%;
        text-align: center;
        line-height: 30px;
        position: relative;
        bottom: 30px;

        @include master.media-mobile {
          font-size: 16px;
          line-height: 23px;
        }
      }
    }

    &.reversed {
      flex-direction: column-reverse;
    }

    @include master.media-tablet {
      padding: 0 20px;
      height: calc(100% - 100px);
      min-height: calc(100% - 180px);
    }
    @include master.media-smalldevice {
      padding: 0 15px;
    }
  }

  .bottom-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 9;
    height: 130px;
    padding: 0 40px 25px;
    width: 100%;
    @include master.custom-flex(flex-end, center);

    @include master.media-tablet {
      height: 100px;
      padding: 0 20px 10px;
    }
    @include master.media-smalldevice {
      padding: 0 15px 10px;
    }
  }
  &.selected {
    .messages-wrapper {
      min-height: calc(100% - 325px) !important;
      @include master.media-tablet {
        min-height: calc(100% - 245px) !important;
      }
    }
  }
}
