@use "../../../../../master.scss";

.top-header {
  @include master.flex-start();
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background: master.$white;
  height: 70px;
  padding: 0 20px;
  border-bottom: 1px solid master.$border-color;

  .left-box {
    @include master.flex-start();
    gap: 35px;
    min-width: 125px;

    .sidebar-toggle {
      background: transparent;
      border: none;
      font-size: 26px;
      cursor: pointer;

      @include master.media-smalldevice {
        // font-size: 22px;
        // display: none;
      }
    }

    .logo-box {
      color: master.$black;
      @include master.flex-start();
      gap: 10px;

      img {
        width: 55px;
      }
      &.text img {
        width: 180px;
        @include master.media-smalldevice {
          width: 150px;
        }
      }
    }
    @include master.media-tablet {
      min-width: 100px;
      gap: 15px;
    }
  }

  .project-title {
    font-size: 22px;
    font-family: master.$font-arial-bold;
    max-width: calc(100% - 260px);
    @include master.text-wrap();
    @include master.media-tablet {
      max-width: calc(100% - 230px);
    }

    @include master.media-smalldevice {
      max-width: calc(100% - 130px);
      font-size: 18px;
    }
  }
  .new-chat {
    margin-left: 25px;
    background: transparent;
    border: none;
    font-size: 16px;
    color: master.$light-black;
    cursor: pointer;
    @include master.flex-start();
    .thumb {
      font-size: 18px;
      width: 21px;
      height: 21px;
      background: master.$light-black;
      color: master.$white;
      border-radius: 50px;
      @include master.flex-center();
      @include master.media-smalldevice {
        width: 35px;
        height: 35px;
        background: master.$light-grey;
        color: master.$black;
      }
    }
    .title {
      margin-left: 8px;
      @include master.media-smalldevice {
        display: none;
      }
    }
    @include master.media-smalldevice {
      margin-left: 15px;
    }
  }
  .setting-toggle {
    position: absolute;
    right: 20px;
    background: master.$light-grey;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    @include master.flex-center();
    transition: 0.3s all;
    &:hover {
      background: master.$grey;
    }
    @include master.media-smalldevice {
      width: 35px;
      height: 35px;
      font-size: 16px;
      right: 15px;
    }
  }

  .toggle-btn {
    display: none;
    background: transparent;
    border: none;
    margin-left: 20px;

    span {
      display: block;
      width: 25px;
      height: 4px;
      background: master.$theme-color;
      margin-bottom: 5px;
      border-radius: 20px;
      transition: 0.3s all;

      &:nth-child(2) {
        width: 21px;
      }

      &:last-child {
        margin-bottom: 0;
        width: 15px;
      }

      @include master.media-mobile {
        height: 3px;
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(6.5px, 1px);
        }

        &:nth-child(2) {
          width: 25px;
          transform: rotate(-45deg) translate(4.5px, 1px);
        }

        &:nth-child(3) {
          display: none;
        }
      }
    }

    @include master.media-tablet {
      @include master.flex-end-column();
    }
  }
}
