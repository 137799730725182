@use "../../master.scss";

.modal-component {
    .ant-modal-content {

        .ant-modal-close {
            top: 22px;
        }

        .modal-title {
            color: master.$theme-color;
            font-size: 18px;
            font-family: master.$font-arial-bold;
            letter-spacing: 0.5px;
            margin-bottom: 15px;
        }

        @include master.media-tablet {
            padding: 20px 15px;
        }
    }
}