@use "../../../../../master.scss";

.message-box {
  margin-top: 20px;

  .message-loader {
    margin: 30px 0 16px;
  }

  .message-content {
    margin: 16px 0;
    font-size: 16px;
    color: master.$dark-text;
    word-break: break-word;

    .images-wrapper {
      width: 100%;
      margin-top: 5px;
      overflow-y: auto;

      .image-slides {
        width: max-content;
        display: flex;
        gap: 10px;

        .media-box {
          border-radius: 8px;
          width: 80px;
          height: 80px;
          overflow: hidden;

          &.image .ant-image {
            width: 100%;
            height: 100%;
            .ant-image-mask-info {
              font-size: 12px;
            }
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          &.file {
            width: max-content;
            @include master.flex-center();
            background: master.$light-grey;
            .thumb {
              background: master.$theme-color-primary;
              width: 45px;
              height: 100%;
              border-radius: 8px 0 0 8px;
              font-size: 20px;
              @include master.flex-center();
            }
            .detail {
              padding: 0 15px;
              min-width: 180px;
              h4 {
                font-family: master.$font-arial-bold;
                margin-bottom: 3px;
                font-size: 15px;
                @include master.media-smalldevice {
                  font-size: 13px;
                }
              }
              p {
                font-size: 13px;
              }
            }
          }

          @include master.media-mobile {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .regenerate-btn {
    border: 1px solid master.$error-color;
    color: master.$error-color;
    background: transparent;
    padding: 7px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      background: master.$error-color;
      color: master.$white;
    }
    @include master.media-mobile {
      font-size: 13px;
      padding: 5px 12px;
    }
  }

  &.request {
    margin-top: 30px;

    .message-content {
      // This lines up the request content with the reply content - 16px padding, 1px border
      padding-left: 17px;
      padding-right: 40px;

      scroll-margin-top: 30px;

      .regenerate {
        position: absolute;
        right: 4px;
        top: 2px;

        @include master.media-tablet {
          font-size: 15px;
          scroll-margin-top: 20px;
        }
      }
      @include master.media-smalldevice {
        padding-right: 30px;
      }
    }

    @include master.media-tablet {
      margin-top: 20px;
    }
  }

  &.response {
    border: 1px solid master.$border-color;
    padding: 16px;
    border-radius: 8px;

    .top-box {
      @include master.flex-between();

      .thumbnail {
        @include master.flex-start();

        .logo {
          width: 45px;
          margin-right: 8px;

          @include master.media-mobile {
            width: 35px;
            margin-right: 5px;
          }
        }

        .title {
          font-family: master.$font-arial-bold;
          font-size: 18px;

          @include master.media-mobile {
            font-size: 16px;
          }
        }
      }

      .speech-box {
        background: master.$button-secondary-background;
        padding: 8px 20px;
        border-radius: 100px;
        gap: 5px;
        @include master.flex-center();

        span {
          font-size: 14px;
          font-family: master.$font-arial-bold;

          @include master.media-mobile {
            font-size: 12px;
          }
        }

        @include master.media-mobile {
          font-size: 13px;
          padding: 6px 14px;
        }
      }
    }

    .bottom-box {
      @include master.flex-end();
      gap: 20px;
      .sources {
        min-height: 48px;
        @include master.flex-start();
        flex-wrap: wrap;
        gap: 12px;
        padding-left: 70px;
        li {
          display: inline-block;
          &.title {
            margin-right: 5px;
            font-size: 14px;
            font-family: master.$font-arial-bold;
            position: absolute;
            left: 0;
            top: 15px;
          }
          &.source-box {
            border: 1px solid master.$light-grey;
            border-radius: 8px;
            font-size: 13px;
            display: flex;
            overflow: hidden;
            a {
              color: master.$theme-color;
              text-decoration: none;
              display: flex;
            }
            .number {
              @include master.flex-center();
              background: master.$light-grey;
              padding: 10px 0;
              width: 35px;
            }
            .source {
              padding: 10px 12px;
            }
          }
        }
      }
      .action-box {
        flex: 1;
        @include master.flex-end();
        gap: 8px;
        button {
          border: none;
          display: block;
          border-radius: 8px;
          width: 50px;
          height: 46px;
          background: master.$offwhite;
          font-size: 18px;
          cursor: pointer;
          transition: 0.3s all;
          @include master.flex-center();

          &:hover {
            background: master.$grey;
          }

          &.active,
          &.ant-tooltip-open {
            // background: master.$theme-color;
            color: master.$theme-color !important;
          }

          @include master.media-tablet {
            width: 40px;
            height: 32px;
            font-size: 16px;
          }
        }
      }
      &.sourced {
        @include master.custom-flex(flex-start, space-between);
        flex-wrap: wrap;
      }
    }

    @include master.media-mobile {
      padding: 12px;
    }
  }
}

@media (max-width: 768px) {
  .image-box {
    width: 100px;
    height: 100px;
  }
  .file-inner-text {
    padding: 10px 50px 10px 15px !important;
  }
  .file-inner-text-static {
    font-size: 10px;
  }
}

.ant-image-preview div {
  width: 100%;
  height: 100%;
}
