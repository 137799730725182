@use "../../../master.scss";

.layout-wrapper {
  .chat-layout {
    margin-top: 70px;
    height: calc(100vh - 70px);
    padding-left: 280px;
    transition: 0.4s all;
    width: calc(100% - 280px);

    @include master.media-tablet {
      padding-left: 0;
      width: 100%;
    }

    &.chat-sidebar-active {
      padding-left: 0;
    }
    &.setting-sidebar-active {
      width: 100%;
    }
    .sidebar-backdrop {
      border: none;
      display: none;
      position: fixed;
      background: master.$black-light;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 99;

      @include master.media-tablet {
        display: block;
      }
    }
  }

  .lock-screen {
    height: 100vh;
    @include master.flex-center-column();

    .thumb {
      font-size: 120px;
      color: master.$theme-color;
      @include master.media-mobile {
        font-size: 80px;
      }
    }

    h3 {
      margin-top: 30px;
      font-size: 30px;
      text-align: center;
      color: master.$theme-color;
      text-transform: uppercase;
      font-family: master.$font-arial-extrabold;

      @include master.media-mobile {
        margin-top: 15px;
        font-size: 20px;
      }
    }
  }
  &.project-app {
    .chat-layout {
      width: 100%;
    }
  }
  // &.rtl {
  // .chat-layout {
  //   padding-left: 0;
  //   padding-right: 280px;

  //   @include master.media-tablet {
  //     padding-right: 0;
  //   }

  //   &.active {
  //     padding-right: 0;
  //   }
  // }
  // .chat-sidebar {
  //   left: auto;
  //   right: 0;
  //   border-right: none;
  //   border-left: 1px solid master.$border-color;
  //   transform: translateX(280px);

  //   @include master.media-desktop {
  //     transform: translateX(240px);
  //   }

  //   @include master.media-tablet {
  //     transform: translateX(0);
  //   }

  //   &.active {
  //     transform: translateX(0);

  //     @include master.media-tablet {
  //       transform: translateX(240px);
  //     }
  //   }
  // }
  // }
}
