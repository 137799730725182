@use "../../../../../master.scss";

.chat-layout {
  .audio-box {
    width: 1200px;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    .top-box {
      @include master.flex-center-column();
      height: calc(100% - 150px);
      .audio-loader,
      .tips {
        position: relative;
        width: 250px;
        height: 250px;
        @include master.flex-center();
        &:not(.listening) {
          opacity: 1 !important;
        }
        span {
          display: block;
          border-radius: 50%;
          position: absolute;
          transition: 0.3s all;
        }
        .voice-loader {
          display: none;
          width: 260px;
          height: 260px;
          max-width: none;
        }

        &.listening,
        &.hold {
          span:nth-child(1) {
            width: 250px;
            height: 250px !important;
            background: master.$audio-loader;
          }
        }
        &.hold span:nth-child(2) {
          width: 220px;
          height: 220px !important;
          background: master.$white;
        }
        &.loading {
          .voice-loader {
            display: block;
          }
          // width: 250px;
          // height: 250px;
          // background: master.$audio-loader;
          // border-radius: 50%;
          // .tips {
          //   // animation: rotate 5s linear infinite;
          // }
          // span {
          //   width: 25px;
          //   height: 25px !important;
          //   background: master.$white;
          //   animation: opacity 1s linear infinite;
          //   transition: 0.3s all;
          //   &:nth-child(1) {
          //     left: 25px;
          //   }
          //   &:nth-child(2) {
          //     left: 60px;
          //     animation-delay: 0.3s;
          //   }
          //   &:nth-child(3) {
          //     left: 95px;
          //     animation-delay: 0.6s;
          //   }
          //   &:nth-child(4) {
          //     left: 130px;
          //     animation-delay: 0.9s;
          //   }
          //   &:nth-child(5) {
          //     left: 165px;
          //     animation-delay: 1.2s;
          //   }
          //   &:nth-child(6) {
          //     left: 200px;
          //     animation-delay: 1.5s;
          //   }
          // }
        }
        &.speaking {
          background: master.$audio-loader;
          border-radius: 50%;
          .tips {
            justify-content: space-between;
            width: 80%;
            margin: auto;
            padding-left: 5px;
          }
          span {
            position: relative;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              width: 14%;
              height: 80px;
              background: master.$white;
              border-radius: 40px;
              // animation: height 1s linear infinite;
            }
            &:nth-child(2) {
              animation-delay: 0.1s;
            }
            &:nth-child(3) {
              animation-delay: 0.2s;
            }
            &:nth-child(4) {
              animation-delay: 0.3s;
            }
            &:nth-child(5) {
              animation-delay: 0.4s;
            }
            &.listening-badge {
              display: none;
            }
          }
        }
      }
      .action-box {
        @include master.flex-center();
        gap: 40px;
        margin-top: 30px;
        button {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 1px solid master.$border-color;
          background: master.$yellowish-white;
          font-size: 28px;
          cursor: pointer;
          transition: 0.3s all;
          &:hover {
            background: master.$grey;
          }
        }
      }
    }
    .bottom-box {
      width: 100%;
      padding: 20px;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      .listening-box {
        border: 1px solid master.$border-color;
        padding: 0 25px;
        height: 50px;
        border-radius: 50px;
        font-size: 20px;
        display: flex;
        align-items: center;
        transform: translateY(140px);
        transition: 1s all;
        background: master.$white;
        width: 230px;
        .frequency {
          margin-left: 30px;
          height: 60px;
          display: flex;
          align-items: center;
          gap: 10px;
          .bar {
            border-radius: 50px;
            width: 15px;
            transform-origin: center bottom;
            transition: height 0.2s ease;
            background: master.$audio-loader;
          }
        }
        &.active {
          display: flex;
          transform: translateY(0);
        }
      }
      .tagline {
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
        @include master.line-wrap(2);
        @include master.media-tablet {
          font-size: 13px;
          margin-top: 8px;
        }

        @include master.media-mobile {
          line-height: 18px;
        }
      }
    }
  }
}

@keyframes size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes listeningsize {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

// @keyframes rotate {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

@keyframes opacity {
  0%,
  100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes height {
  0% {
    height: 100px;
  }
  50% {
    height: 120px;
  }
  100% {
    height: 100px;
  }
}
