@use "../../../../../master.scss";

.media-library-wrapper {
  .modal-title {
    font-size: 20px;
    margin-bottom: 4px !important;
  }
  .tagline {
    margin-bottom: 15px;
    span {
      font-family: master.$font-arial-bold;
    }
  }

  .top-box {
    @include master.flex-start();
    gap: 15px;
    margin-bottom: 30px;

    .search-box {
      width: calc(100% - 130px);
      border: 1px solid master.$border-color;
      border-radius: 8px;
      @include master.flex-start();
      overflow: hidden;

      svg {
        color: master.$theme-color;
        font-size: 17px;
        position: absolute;
        left: 12px;
        z-index: 2;
      }

      input {
        border: none;
        padding: 13px;
        padding-left: 40px;
        width: 100%;
        font-size: 15px;
      }

      @include master.media-tablet {
        width: 100%;
      }
    }

    .upload-box {
      width: 150px;
      background: master.$button-secondary-background;
      border-radius: 12px;
      padding: 14px 0;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      transition: 0.3s all;

      input {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &:hover {
        background: master.$button-secondary-hover-background;
      }

      @include master.media-tablet {
        width: auto;
        padding: 8px 20px;
        font-size: 13px;
      }
    }

    @include master.media-tablet {
      @include master.flex-end-column-reverse();
    }
  }

  .media-listing {
    max-height: 380px;
    min-height: 300px;
    overflow-y: auto;
    padding-right: 10px;

    .listing-header,
    .listing-item {
      cursor: pointer;
      @include master.flex-start();

      span {
        font-size: 13px;
        padding: 15px 10px;
        min-width: 100px;
        @include master.text-wrap();
        border-bottom: 1px solid master.$border-color;

        &.name {
          width: 40%;
          min-width: 150px;
        }

        &.upload {
          width: 20%;
        }

        &.size {
          width: 20%;
        }

        &.status {
          width: 20%;
        }

        // &.name {
        //     width: 27%;
        //     min-width: 150px;
        // }

        // &.upload {
        //     width: 20%;
        // }

        // &.type {
        //     width: 15%;
        // }

        // &.size {
        //     width: 15%;
        // }

        // &.token {
        //     width: 23%;
        // }
      }

      .item-checkbox {
        position: absolute;
        min-width: unset;

        span {
          min-width: unset;
          padding: 5px;
          border: none;
        }

        .ant-checkbox-inner {
          padding: 0;
          width: 18px;
          height: 18px;
          border: 1px solid #d9d9d9;
        }

        &:hover .ant-checkbox-inner {
          border-color: master.$theme-color;
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: master.$theme-color !important;
            background-color: master.$theme-color !important;
          }
        }
      }

      &.listing-header {
        background: master.$white;
        position: sticky;
        top: 0;
        z-index: 2;

        span {
          padding: 6px 10px;
          font-size: 15px;
          font-family: master.$font-arial-medium;
          color: master.$theme-color;
        }
      }

      &.listing-item {
        span {
          &.name {
            padding-left: 65px;

            svg {
              position: absolute;
              left: 40px;
              top: 17px;
              font-size: 16px;
              color: master.$theme-color;
            }
          }

          &.status {
            padding-right: 50px;
            text-align: center;

            .delete-btn {
              top: 18px;
              position: absolute;
              right: 5px;
              color: master.$theme-color;
              cursor: pointer;
            }
          }

          // &.token {
          //     padding-right: 30px;

          //     .delete-btn {
          //         top: 18px;
          //         position: absolute;
          //         right: 5px;
          //         color: master.$theme-color;
          //         cursor: pointer;
          //     }
          // }
        }
      }

      &:last-child span {
        border: none;
      }
    }

    .no-media-wrapper {
      cursor: auto;
      height: 250px;
      @include master.flex-center-column();

      img {
        width: 90px;
      }

      h4 {
        margin-top: 20px;
        font-size: 21px;
        font-family: master.$font-arial-medium;
      }
    }
  }

  .bottom-box {
    margin-top: 20px;
    min-height: 50px;
    gap: 20px;
    @include master.flex-end();

    button {
      padding: 12px 25px;
      min-width: 140px;
    }
  }
}
