@use "../../master.scss";

.access-denied-wrapper {
  min-height: 100vh;
  border-bottom: 30px solid master.$theme-color-primary;
  @include master.flex-center();

  .content-box {
    @include master.flex-center();
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;

    .thumb {
      width: 300px;
      @include master.media-smalldevice {
        width: 160px;
        margin-bottom: 30px;
      }
    }

    .content {
      width: 600px;
      max-width: 100%;
      padding-left: 30px;
      h4 {
        font-size: 32px;
        margin-bottom: 5px;
        @include master.media-tablet {
          font-size: 24px;
        }
      }
      h2 {
        font-size: 45px;
        font-family: master.$font-arial-bold;
        margin-bottom: 20px;

        @include master.media-tablet {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;

        @include master.media-tablet {
          font-size: 15px;
          margin-bottom: 10px;
        }
      }

      .request-btn {
        display: inline-block;
        cursor: pointer;
        background: master.$button-background;
        color: #fff;
        border-radius: 50px;
        font-size: 16px;
        padding: 13px 32px;
        &:hover {
          background: master.$button-hover-background;
        }
        @include master.media-tablet {
          font-size: 14px;
          padding: 12px 25px;
        }
      }
      @include master.media-smalldevice {
        padding: 0;
      }
    }

    @include master.media-smalldevice {
      @include master.flex-start-column();
      padding: 0 20px;
    }
  }

  @include master.media-smalldevice {
    border-width: 20px;
  }
}
