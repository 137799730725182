@use "../../../../../master.scss";

.setting-sidebar {
  top: 70px;
  transition: 0.4s all;
  position: fixed;
  height: calc(100% - 70px);
  width: 280px;
  padding-bottom: 30px;
  background: master.$white;
  overflow-y: auto;
  z-index: 99;
  right: 0;
  border-left: 1px solid master.$border-color;
  transform: translateX(280px);

  .top-box {
    margin-top: 30px;
    .project-detail-box {
      padding: 0 16px;
      margin-top: 25px;

      h4 {
        color: master.$theme-color;
        font-size: 20px;
        font-family: master.$font-arial-bold;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
      }
    }
    @include master.media-mobile {
      margin-top: 20px;
    }

    .initiate-box {
      padding: 0 16px;

      .field-label {
        font-size: 12.9px;
        margin-top: 16px;
        margin-bottom: 8px;
      }

      .model-selector {
        .selected-box {
          cursor: pointer;
          border: 1px solid master.$border-color;
          border-radius: 8px;
          padding: 16px;
          display: block;
          background: transparent;
          text-align: left;
          width: 100%;

          .toggle {
            position: absolute;
            right: 16px;
            transition: 0.3s all;

            @include master.media-desktop {
              right: 12px;
            }
          }

          span {
            display: block;
            font-size: 14px;

            &.selected {
              margin-top: 5px;
              font-size: 18px;
              font-family: master.$font-arial-bold;
              @include master.text-wrap();
          
              .eco {
                margin-left: 10px;
                color: #6db33f;
                display: inline;
                font-size: 18px;
              }
            }

            @include master.media-mobile {
              font-size: 13px;

              &.selected {
                font-size: 16px;
              }
            }
          }

          @include master.media-desktop {
            padding: 12px;
          }
        }

        .model-options {
          position: absolute;
          width: 100%;
          top: 63px;
          z-index: 2;
          background: master.$white;
          transition: height 0.3s;
          border-radius: 0 0 8px 8px;
          height: 0;
          overflow: hidden;
          max-height: 190px;
          overflow-y: auto;

          button {
            border: none;
            background: transparent;
            text-align: left;
            width: 100%;
            padding: 10px 16px;
            display: block;
            font-size: 14px;
            cursor: pointer;
            transition: 0.3s all;
            .eco {
              margin-left: 5px;
              color: #6db33f;
            }
            &:hover {
              background: master.$offwhite;
            }

            @include master.media-desktop {
              padding: 8px 14px;
            }

            @include master.media-mobile {
              padding: 6px 14px;
              font-size: 13px;
            }
          }

          @include master.media-mobile {
            top: 55px;
          }
        }

        &.active {
          .selected-box {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: transparent;

            .toggle {
              transform: rotate(180deg);
            }
          }

          .model-options {
            height: max-content;
            padding: 5px 0;
            border: 1px solid master.$border-color;
            border-top-color: transparent;
          }
        }
      }

      .restrict-box {
        background: transparent;
        margin-top: 16px;
        cursor: pointer;
        border: 1px solid master.$border-color;
        border-radius: 8px;
        padding: 16px;
        width: 100%;
        transition: 0.3s all;
        @include master.flex-start-column();
        // @include master.flex-between();

        span {
          font-family: master.$font-arial-bold;
          margin-bottom: 10px;
          font-size: 14px;
        }

        &:hover {
          border-color: master.$theme-color;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .chat-options {
        margin-top: 8px;
        @include master.flex-wrap();
        gap: 11px;
        margin-bottom: 12px;

        .chat-option {
          transition: 0.3s all;
          cursor: pointer;
          display: block;
          margin-top: 8px;
          padding: 12px 16px;
          background: master.$offwhite;
          border-radius: 8px;
          font-size: 16px;

          &:hover {
            background: master.$grey;
          }

          @include master.media-desktop {
            font-size: 14px;
            padding: 10px 14px;
          }

          @include master.media-mobile {
            font-size: 13px;
            padding: 8px 12px;
          }
        }
      }

      .prompt-mode-dropdown {
        border: 1px solid master.$border-color;
        border-radius: 4px;
        overflow: hidden;

        .selected-option {
          cursor: pointer;
          background: transparent;
          border: none;
          text-align: left;
          padding: 15px;
          padding-right: 30px;
          h4 {
            font-family: master.$font-arial-bold;
            margin-bottom: 6px;
          }
          p {
            font-size: 13px;
            color: master.$dark-text;
            margin-bottom: 0;
            @include master.line-wrap(4);
          }
          .toggle-btn {
            position: absolute;
            right: 10px;
            top: 15px;
            transition: 0.3s all;
          }
        }
        .dropdown-options {
          max-height: 200px;
          overflow: hidden;
          height: 0;
          transition: height 0.3s;
          button {
            cursor: pointer;
            padding: 10px 15px;
            font-size: 12px;
            color: master.$dark-text;
            border: none;
            background: transparent;
            text-align: left;
            border-bottom: 1px solid master.$light-grey;
            span {
              display: block;
              &:first-child {
                font-size: 13px;
                font-family: master.$font-arial-bold;
                margin-bottom: 6px;
              }
            }
            &:last-child {
              border-bottom: none;
            }
            &.selected{
              background: master.$grey;
            }
          }
        }
        &.active {
          .selected-option .toggle-btn {
            transform: rotate(180deg);
          }
          .dropdown-options {
            overflow-y: auto;
            height: max-content;
          }
        }
      }

      @include master.media-mobile {
        padding: 0 14px;
      }
    }
  }

  .media-library {
    text-align: left;
    width: calc(100% - 32px);
    background: transparent;
    margin: 0 16px;
    margin-top: 16px;
    border: 1px solid master.$border-color;
    border-radius: 8px;
    padding: 16px;
    transition: 0.3s all;
    cursor: pointer;

    svg {
      font-size: 18px;
      color: master.$theme-color;
    }

    span {
      font-size: 16px;
      margin-left: 15px;
    }

    &:hover {
      border-color: master.$theme-color;
    }
  }

  .selected-media-box {
    margin: 0 16px;
    margin-top: 25px;

    h3 {
      font-size: 17px;
      font-family: master.$font-arial-medium;
      margin-bottom: 5px;
    }

    ul {
      li {
        display: block;
        padding: 10px 26px;
        font-size: 14px;
        border-bottom: 1px solid master.$light-grey;
        @include master.text-wrap();

        .thumb {
          position: absolute;
          left: 0;
          top: 8px;
          color: master.$theme-color;
          font-size: 18px;
        }

        .delete-btn {
          position: absolute;
          color: master.$theme-color;
          right: 0;
          font-size: 15px;
          top: 11px;
          cursor: pointer;
        }
      }
    }
  }

  @include master.media-mobile {
    padding-bottom: 20px;
  }

  @include master.media-desktop {
    width: 240px;
    transform: translateX(240px);
  }

  @include master.media-tablet {
    transform: translateX(0);
  }

  &.active {
    transform: translateX(0);

    @include master.media-tablet {
      transform: translateX(240px);
    }
  }
}
