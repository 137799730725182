.video-container {
    width: 100%;
    text-align: center;
    height: calc(100% - 130px);
    min-height: calc(100% - 230px);
}

#my-video-element-id {
    width: 42vw;
}

.blur-wrapper {
    position: relative;
    height: 100vh;
}

.loader-class {
    top: 45%;
    transform: translate(-43%, -60%);
    left: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
